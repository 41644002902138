import { ReactElement } from "react";
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { NumberQuestionType } from "../../../../types/Question";
import { useTranslation } from "react-i18next";
import { getDecimalStep } from "../../../../helper/number";
import { useConfig } from "../../../../contexts/ConfigContext";
import useVocalisation from "../../../../hooks/useVocalisation";
import { micOutline } from "ionicons/icons";

interface AnswerNumberProps {
  question: NumberQuestionType;
  value?: number;
  onChange: (value: string | null) => void;
}

const AnswerNumber = ({
  question,
  value = 0,
  onChange,
}: AnswerNumberProps): ReactElement => {
  const { t } = useTranslation();

  const { selectedRole } = useConfig();

  const { startListnening, isListening } = useVocalisation([], (value) =>
    onChange(value)
  );

  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;
  const { detail } = question;

  const { min_value, max_value, number_decimal, prefix, suffix } = detail;

  const step: string = getDecimalStep(number_decimal).toString();
  const inputmode: "tel" | "decimal" = number_decimal === 0 ? "tel" : "decimal";

  const handleChange = (e: CustomEvent): void => {
    const value = e.detail.value?.replace(/[^0-9.]/, "");
    onChange(value);
  };

  return (
    <>
       <IonItem>
        {prefix && (
          <IonLabel slot="start" color="medium">
            <p className="res-text-small">{prefix}</p>
          </IonLabel>
        )}
        <IonInput
          min={min_value.toString()}
          max={max_value.toString()}
          value={value}
          inputmode={inputmode}
          onIonChange={handleChange}
          type="text"
          placeholder={t("answer_number_placeholder")}
          step={step}
          clearInput
          className="res-text-input"
        />
        {suffix && (
          <IonLabel slot="end" color="medium">
            <p className="res-text-small">{suffix}</p>
          </IonLabel>
        )}
      </IonItem>
      {isSpeechRecEnabled && (
        <IonItem lines="full">
          <IonLabel>
            <p>{t("common_click_to_speak")}</p>
          </IonLabel>

          <IonButton onClick={startListnening} slot="end" fill="clear">
            {isListening ? (
              <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
            ) : (
              <IonIcon slot="icon-only" icon={micOutline} />
            )}
          </IonButton>
        </IonItem>
      )}
    </>
  );
};

export default AnswerNumber;
